import { getTrafficType } from "../../../utils";

export const createHeaders = () => {
	const headers = [
		"Status",
		"Campaign ID",
		"Campaign Name",
		"Link"
	];
	return headers;
};

export const createOnsiteCSVItems = async (allResults, dataMap) => {
	const csvItems = [];
	const headers = createHeaders();
	csvItems.push(headers);

	for (let i = 0; i < allResults.length; i++) {
		const result = allResults[i];
		const mode = getTrafficType(dataMap[result.id]);
		csvItems.push([
			mode,
			result.id,
			dataMap[result.id].attributes.name,
			result.url,
		]);
	}
	return csvItems;
};

export const createInboxCSVItems = async(allResults, dataMap) => {
	const csvItems = [];
	const headers = createHeaders();

	headers.push('Link with Subject and Preview Text');

	csvItems.push(headers);

	for (let i = 0; i < allResults.length; i++) {
		const result = allResults[i];

		if (result.value.status === 200) {
			const data = await result.value.json();
			const mode = getTrafficType(dataMap[data.id]);
			csvItems.push([
				mode,
				data.id,
				dataMap[data.id].attributes.name,
				data.webview_link,
				`${data.webview_link}&show_preview=1`
			]);
		}
	}

	return csvItems;
};

export const buildDownloads = async (csvItems, name, type) => {
	let csvContent = "";
	csvItems.forEach((row) => {
		csvContent += row.map((r) => {
			return `"${r}"`;
		}).join(",") + "\n";
	});

	const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
	const objUrl = URL.createObjectURL(blob);

	const a = await document.createElement("a");

	a.href = objUrl;
	a.download = `${name} ${type} Campaigns.csv`;
	a.click();
};
